<div class="container-fluid footer-container bottom-0">
  <div class="px-md-5">
    <div class="row">
      <div class="col">
        <footer
          id="footer"
          class="shared-footer"
          [ngClass]="{ 'mobile-popup': hasMobilePopupFooter, fr: (lang | async) === 'fr' }">
          <ul class="nav pb-2 mb-2 flex-md-row mb-md-3">
            <li *ngFor="let nav of navs">
              <div class="d-flex">
                <a
                  [href]="nav.url | translate"
                  class="nav-link link-primary-dark"
                  id="linkFooterMenu"
                  target="_blank"
                  title="{{ nav.menuitem | translate }}"
                  [attr.aria-label]="'external link to ' + (nav.menuitem | translate)">
                  {{ nav.menuitem | translate }}
                </a>
                <a
                  [href]="nav.url | translate"
                  class="link-primary-dark ms-2 text-decoration-none"
                  id="linkFooterMenu_icon"
                  target="_blank"
                  title="{{ nav.menuitem | translate }}"
                  [attr.aria-label]="'external link to ' + (nav.menuitem | translate)">
                  <span class="fal fa-external-link fs-6" aria-hidden="true"></span>
                </a>
              </div>
            </li>
          </ul>
          <p class="copyright mt-4 mt-md-0">© {{ 'shared.footer.copyRight' | translate }}</p>
        </footer>
      </div>
    </div>
  </div>
</div>
