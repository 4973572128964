import { NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { I18N_VALUES } from './custom-date-picker-I18n';
import dayjs from 'dayjs';

@Injectable()
export class CustomDateFormatter extends NgbDateParserFormatter {
  language = 'en';
  constructor(private translateService: TranslateService) {
    super();
    this.language = this.translateService.currentLang;
  }

  getDay(value: string): number {
    const splitDate = value.split(' ');
    return Number(splitDate[0]);
  }

  getMonth(value: string): number {
    const splitDate = value.split(' ');
    return I18N_VALUES[this.language].months.findIndex(
      (month: string) => month?.toLowerCase() === splitDate[1]?.toLowerCase()
    );
  }

  getYear(value: string): number {
    const splitDate = value.split(' ');
    return Number(splitDate[2]);
  }

  parse(value: string): NgbDateStruct | null {
    if (!value) return null;
    const monthStr = value.split(' ')[1];
    const monthIndx = this.getMonth(value);
    const monthEn = I18N_VALUES['en']?.months[monthIndx];
    // if no matching month, invalid
    if (!monthEn) return null;
    // convert month to English, so we can use new Date(value) which only works with English strings
    value = value.replace(monthStr, monthEn);

    const dt = dayjs(new Date(value)).isValid()
      ? new Date(this.getYear(value), this.getMonth(value), this.getDay(value))
      : new Date(value);
    return {
      year: dt.getFullYear(),
      month: dt.getMonth() + 1,
      day: dt.getDate(),
    };
  }

  format(date: NgbDateStruct): string {
    if (!date) return '';
    return `${date.day} ${I18N_VALUES[this.language]?.months[date.month - 1]} ${date.year}`;
  }
}
